import React, { useState, useEffect } from 'react';
import NavbarCore from '../components/navBar/NavBar.js';
import './style/Home.css';
import LeftBar from '../components/navBar/LeftBar.js';
import { products,filterProducts } from '../core/products.js';
import { useNavigate,useLocation} from 'react-router-dom';
import {addToCart} from '../core/cartActions';
import Footer from '../components/footer/Footer.js';

import { Items } from '../components/navBar/LeftBar.js';

export let facilitiesItems = [
    {
        icon: '/media/icons/courier.png',
        content: 'Livrari prin curier în 48h'
    },
    {
        icon: '/media/icons/product-return.png',
        content: 'Retur în 14 zile'
    },
    {
        icon: '/media/icons/sage.png',
        content: 'Plată securizată'
    },
]

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const pageTypes = {
    pahare:{
        title: 'Pahare',
        headerTitle: 'Pahare',
        // bgImage: PahareBg
    },
    caserole: {
        title: 'Caserole',
        headerTitle: 'Caserole',
        // bgImage: CaserolaBg
    },
    farfuriiBio:{
        title: 'Farfurii Bio',
        headerTitle: 'Farfurii Bio',
        // bgImage: FarfurieBg
    },
    farfuriiCarton:{
        title: 'Farfurii din Carton',
        headerTitle: 'Farfurii Carton',
        // bgImage: FarfurieBg
    },
    curatenie:{
        title: 'Produse pentru curatenie',
        headerTitle: 'Produse pentru curatenie',
        // bgImage: CuratenieBg
    },
    diverse:{
        title: 'Produse diverse',
        headerTitle: 'Produse diverse',
        // bgImage: DiverseBg
    }
    
}

const Categoryes = ()=>{

    let query = useQuery();
    useEffect(()=>{
        document.title = 'Ghazal Ambalaje Acasa'
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])

    useEffect(()=>{
        let queryCategory = query.get('categorie')
        if(queryCategory === 'farfurii-bio')
        {
            setCurrentCategory('farfuriiBio')
        }
        else if(queryCategory === 'farfurii-carton')
        {
            setCurrentCategory('farfuriiCarton')
        }
        else if(queryCategory === 'curatenie')
        {
            setCurrentCategory('curatenie')
        }
        else if(pageTypes[queryCategory] !== undefined)
        {
            setCurrentCategory(queryCategory)
        }
        
    },[query.get('categorie')])

    
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [currentCategory, setCurrentCategory] = useState('pahare');
    
    useEffect(()=>{
        setFilteredProducts(filterProducts(products, currentCategory));
    },[currentCategory])


    const handleCategoryChange = (newCategory)=>{
        setCurrentCategory(newCategory);
    }

    return(
        <>
            <NavbarCore />
            <div className='homepage-container'>
                <div className='homepage-container-padding'>
                    <LeftBar handleCategoryChange={handleCategoryChange} />
                    <div className='homepage-container-padding-content'>
                        <div className='homepage-container-padding-content-core'>
                            <div className='homepage-container-padding-content-core-desc'>
                                {
                                    facilitiesItems.map((el)=>{ 
                                        return <FacilitiesItem {...el}/>
                                    })
                                }
                            </div>
                            <div className='homepage-container-padding-content-core-mobile-selector'>
                                {
                                    Items.map((el)=>{
                                        if(el.items.length == 1)
                                        {
                                            return(
                                                <div className='homepage-container-padding-content-core-mobile-selector-item'>
                                                    <span 
                                                        onClick={()=>handleCategoryChange(el.items[0].raw)}
                                                    >{el.name}</span>
                                                </div>
                                            )
                                        }
                                        else 
                                        {
                                            let extractName = el.name;
                                            return el.items.map((el_deep)=>{
                                                return(
                                                    <div className='homepage-container-padding-content-core-mobile-selector-item'>
                                                        <span 
                                                            onClick={()=>handleCategoryChange(el_deep.raw)}
                                                        >{`${extractName} ${el_deep.name}`}</span>
                                                    </div>
                                                )
                                            })

                                        }
                                        
                                    })
                                }
                            </div>
                            <div className='homepage-container-padding-content-core-current-category'>
                                <p>Categorie: <b>{pageTypes[currentCategory].title}</b></p>
                            </div>

                            <div className='homepage-container-padding-content-products'>
                            {
                                filteredProducts.map((el)=>{
                                    return <ProductItem {...el}/>
                                })
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export const FacilitiesItem = ({icon, content})=>{
    return(
        <div className='homepage-container-padding-content-core-desc-item'>
            <img src={icon} />
            <span>{content}</span>
        </div>
    )
}

const ProductItem = (props)=>{
    
    const navigate = useNavigate();

    const handleRedirect = (dest)=>{
        navigate(`/product?id=${dest}`);
    }

    return(
        <div
            onClick={()=>handleRedirect(props.id)} 
            className='home-product-item-container'>
            <div 
                className='home-product-item-container-img'
                style={{
                    backgroundImage: `url(${props.img})`
                }}
                >
                <div
                    onClick={()=>addToCart(props.id,1)} 
                    className='home-product-item-container-img-abs'>
                    <img src="/media/icons/add-to-cart.png"/>
                </div>
            </div>
            <div className='home-product-item-container-desc'>
                <div className='home-product-item-container-desc-name'>
                    <span>{props.title}</span>
                </div>
                <div className='home-product-item-container-desc-price'>
                    <span className='home-product-item-container-desc-price-1'>
                        {props.price.first}.{props.price.second} Lei
                    </span>
                    <span className='home-product-item-container-desc-price-2'>*preț fără TVA </span>
                </div>
            </div>
        </div>
    )
}

export default Categoryes;