import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { useEffect } from "react"
import PaymentForm from './PaymentForm';

// live
const PUBLIC_KEY = "pk_live_51MFY2iDb5ctgT8rMXDGe54p1U8l0MRdka4qQwfstAeYn7Dl6TRWUv1Vm8RKpoYd2Tviy18SSunpWvj9vZoNIn54S00icx9CkqO"

//test
// const PUBLIC_KEY = "pk_test_51MFY2iDb5ctgT8rMjN824yq3tggfGPEG1KqLj32GsKksWqAIk7x0mmff27pMjUDN1I8XlAhIHOouwPoHoJ5oRb8U004VSnlY2L"

const stripeTestPromise = loadStripe(PUBLIC_KEY)

export default function StripeContainer({collectPayload}) {
	return (
		<Elements stripe={stripeTestPromise}>
			<PaymentForm collectPayload={collectPayload}/>
		</Elements>
	)
}
