import React, { useState, useEffect } from 'react';
import './style/Home.css';
import NavbarCore from '../components/navBar/NavBar.js';
import { products,filterProducts } from '../core/products.js';
import { useNavigate } from 'react-router-dom';
import {addToCart} from '../core/cartActions';
import Footer from '../components/footer/Footer.js';


import Pahare_1 from '../media/categories/pahare_1.jpg';
import Pahare_2 from '../media/categories/pahare_2.jpg';
import Caserola_1 from '../media/categories/caserola_1.jpg';
import Caserola_2 from '../media/categories/caserola_2.jpg';
import Curatenie_1 from '../media/categories/curatenie_1.jpg';
import Curatenie_2 from '../media/categories/curatenie_2.jpg';
import FarfuriiBio_1 from '../media/categories/farfurii_bio_1.jpg';
import FarfuriiBio_2 from '../media/categories/farfurii_bio_2.jpg';
import FarfuriiCarton_1 from '../media/categories/farfurii_carton_1.jpg';
import FarfuriiCarton_2 from '../media/categories/farfurii_carton_2.jpg';
import Diverse_1 from '../media/categories/diverse_1.jpg';
import Diverse_2 from '../media/categories/diverse_2.jpg';


export let facilitiesItems = [
    {
        icon: '/media/icons/courier.png',
        content: 'Livrări prin curier în 48h'
    },
    {
        icon: '/media/icons/product-return.png',
        content: 'Retur în 14 zile'
    },
    {
        icon: '/media/icons/sage.png',
        content: 'Plată securizată'
    },
]

let categoriesHomePage = [
    {
      link: '/categorii?categorie=pahare',
      data:{
          title: 'Pahare',
          title1: 'Pahare',

          header: 'Alege calitatea!',
          header1: 'Pahare de unica folosinta',

          bg: Pahare_1,
          bg1: Pahare_2
        },
    },
    {
      link: '/categorii?categorie=caserole',
      data:{
          title: 'Caserole',
          title1: 'Caserole',

          header: 'Alege calitatea!',
          header1: 'Caserole de unica folosinta',

          bg: Caserola_1,
          bg1: Caserola_2
        },
    },
    {
      link: '/categorii?categorie=curatenie',
      data:{
          title: 'Produse curățenie',
          title1: 'Produse curățenie',

          header: 'Alege calitatea!',
          header1: 'Produse curățenie',

          bg: Curatenie_1,
          bg1: Curatenie_2
        },
    },
    {
      link: '/categorii?categorie=farfurii-bio',
      data:{
          title: 'Farfurii Bio',
          title1: 'Farfurii Bio',

          header: 'Alege calitatea!',
          header1: 'Produse BIO',

          bg: FarfuriiBio_1,
          bg1: FarfuriiBio_2
        },
    },
    {
      link: '/categorii?categorie=farfurii-carton',
      data:{
          title: 'Farfurii Carton',
          title1: 'Farfurii Carton',

          header: 'Alege calitatea!',
          header1: 'Produse carton',
          
          bg: FarfuriiCarton_1,
          bg1: FarfuriiCarton_2
        },
    },
    {
      link: '/categorii?categorie=diverse',
      data:{
        title: 'Produse diverse',
        title1: 'Produse diverse',

        header: 'Alege calitatea!',
        header1: 'Alege calitatea!',
        
        bg: Diverse_1,
        bg1: Diverse_2
      },
    },
    // {
    //   name: 'Caserole',
    //   link: '/categorii?categorie=caserole',
    //   category: 'caserole'
    // },
    // {
    //   name: 'Farfurii Bio',
    //   link: '/categorii?categorie=farfurii-bio',
    //   category: 'farfuriiBio'
    // },
    // {
    //   name: 'Farfurii Carton',
    //   link: '/categorii?categorie=farfurii-carton',
    //   category: 'farfuriiCarton'
    // },
    // {
    //   name: 'Produse Diverse',
    //   link: '/categorii?categorie=diverse',
    //   category: 'diverse'
    // }
]

let mobileBanners = [
    "/media/bg/fb_banner.jpg",
    "/media/bg/new-year.png",
    "/media/bg/craciun.jpg"
]
let desktopBanners = [
    "/media/bg/fb_banner_desktop.jpg",
    "/media/bg/banner_1_dec.jpg",
]

const Home = ()=>{
    
    const [banners, setBanners] = useState({
        style: 'contain',
        items: [...mobileBanners]
    })
    
    const handleDeviceSize = ()=>{
        console.log("resize:", window.innerWidth)
        let mobileBreakpoint = 700;
        if(window.innerWidth < mobileBreakpoint)
        {
            console.log("case 1")
            setBanners({
                style: 'cover',
                items: [...mobileBanners]
            })
        }
        else 
        {
            console.log("case 2")
            setBanners({
                style: 'contain',
                items: [...desktopBanners]
            })
        }
    }

    useEffect(() => {
        handleDeviceSize();
    }, []);

    useEffect(()=>{
        document.title = 'Ghazal Ambalaje Acasă'
    },[])

    const [currentBanner, setCurrentBanner] = useState(0);
    const handleBannerClick = (direction)=>{
        if(direction == 'left')
        {
            if(currentBanner == 0)
            {
                setCurrentBanner(banners.items.length-1);
            }
            else 
            {
                setCurrentBanner((prev)=>{
                    return prev-1;
                }) 
            }
        }
        else if(direction == 'right')
        {
            if(currentBanner == banners.items.length -1)
            {
                setCurrentBanner(0);
            }
            else 
            {
                setCurrentBanner((prev)=>{
                    return prev+1;
                })
            }
        }
    }

    return(
        <>
            <NavbarCore />
            <div className='homepage-container'>
                <div className='homepage-container-padding'>
                    <div className='homepage-container-padding-content'>
                        <div className='homepage-container-padding-content-top'>
                            <div
                                onClick={()=>handleBannerClick("left")} 
                                className='homepage-container-padding-content-top-left'>
                                <img src="/media/icons/left-arrow.png"/>
                            </div>
                            <div
                                onClick={()=>handleBannerClick("right")} 
                                className='homepage-container-padding-content-top-right'>
                                <img src="/media/icons/right-arrow.png"/>
                            </div>
                            <img 
                                style={{
                                    objectFit: banners.style
                                }}
                                src={banners.items[currentBanner]}/>
                        </div>
                        <div className='homepage-container-padding-content-core'>
                            <div className='homepage-container-padding-content-core-desc'>
                                {
                                    facilitiesItems.map((el)=>{ 
                                        return <FacilitiesItem {...el}/>
                                    })
                                }
                            </div>
                            <div className='home-categoryes-container'>
                                {
                                    categoriesHomePage.map((el)=>{
                                    return(
                                        <CategoryItem {...el}/>
                                    )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export const FacilitiesItem = ({icon, content})=>{
    return(
        <div className='homepage-container-padding-content-core-desc-item'>
            <img src={icon} />
            <span>{content}</span>
        </div>
    )
}

const CategoryItem = (props)=>{
    const navigate = useNavigate()
  
    const handleReditect = (dest)=>{
      navigate(dest);
    }
  
    return(
      <div className='home-category-container-item'>
        <div 
            className='home-category-container-item-layer-1'
            style={{
              backgroundImage: `url(${props.data.bg})`
            }}
          >
            <div className='home-category-container-item-layer-header'>
              <span>{props.data.header}</span>
            </div>
            <div className='home-category-container-item-layer-title'>
              <span>{props.data.title}</span>
            </div>
            <div className='home-category-container-item-layer-action'>
                <button onClick={()=>handleReditect(props.link)}>Vezi oferte</button>
            </div>
        </div>
        <div 
            className='home-category-container-item-layer-2'
            style={{
              backgroundImage: `url(${props.data.bg1})`
            }}
          >
            <div className='home-category-container-item-layer-header'>
              <span>{props.data.header1}</span>
            </div>
            <div className='home-category-container-item-layer-title'>
              <span>{props.data.title1}</span>
            </div>
            <div className='home-category-container-item-layer-action'>
                <button onClick={()=>handleReditect(props.link)}>Vezi oferte</button>
            </div>
        </div>
      </div>
    )
}

export default Home;