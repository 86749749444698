import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Link,
    useNavigate  ,
  } from "react-router-dom";
import {countCartProducts,calculateTotalPrice} from '../../core/cartActions';
import './navBar.css';
import BurgerIcon from '../../media/icons/menu.png';

const ItemsLeft = [
   {
      icon: '/media/icons/phone-call.png',
      type: 'text',
      content: '+40 756 233 895'
   },
   // {
   //    icon: '/media/icons/email.png',
   //    type: 'text',
   //    content: 'ambalaje@ghazalholding.ro'
   // },
]

const ItemsRight = [
  {
   icon: '/media/icons/facebook.png',
   to: 'https://www.facebook.com/profile.php?id=100087890926146'
  },
  {
   icon: '/media/icons/instagram.png',
   to: 'https://www.instagram.com/ghazal.ambalaje/'
  }
]

const NavBarLinks = [
   {
      content: 'Acasă',
      to: '/'
   },
   {
      content: 'Categorii',
      to: '/categorii'
   },
   {
      content: 'Despre',
      to: '/despre'
   },
   {
      content: 'Contact',
      to: '/contact'
   },
   {
      content: 'Coș de cumpărături',
      to: '/checkout'
   },
]

const NavbarCore = ()=>{
   const navigate = useNavigate()

   const [howMany, setHowMany] = useState({
      counter: 0,
      total: 0.00
   });

   useEffect(()=>{
      setInterval(()=>{
         setHowMany({
            counter: countCartProducts(),
            total: calculateTotalPrice()
         })
      },100)
   },[])

   const handleIconRedirect = (dest)=>{
      navigate(dest)
   }

   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

   const handleMobileMenuClick = ()=>{
      setMobileMenuOpen((prev)=>{
         return !prev
      })
   }

    return(
      <div className='navbar-container'>
         <div className='navbar-container-info-bar'>
            <div className='navbar-container-info-bar-padding'>
               <div className='navbar-container-info-bar-left'>
                  {
                     ItemsLeft.map((el)=>{
                        return <InfoBarItem {...el}/>
                     })
                  }
               </div>
               <div className='navbar-container-info-bar-center'>
                  <span><b>Program:&nbsp;&nbsp;</b></span>
                  <span>Luni-Vineri 8:00-16:00&nbsp;&nbsp;</span> 
                  <span>Sambata 8:00-13:00</span>
               </div>
               <div className='navbar-container-info-bar-right'>
                  {
                     ItemsRight.map((el)=>{
                        return <a href={el.to}>
                           <img src={el.icon}/>
                           </a>
                     })
                  }
               </div>
            </div>
         </div>

         <div 
            className='navbar-container-info-bar-demo'
            style={{ backgroundImage: "url(/bg-nav-bar.jpg)" }}
            >
            <div className='navbar-container-info-bar-demo-padding'>
               <div 
                  className='navbar-container-info-bar-demo-logo'
                  >
                  <img
                     onClick={()=>handleIconRedirect('/')} 
                     src='/media/logo.png'/>
               </div>
               <div className='navbar-container-info-bar-demo-logo-text'>
                  <span><i>Ghazal Ambalaje în mâinile tale.</i></span>
               </div>

            </div>
         </div>

         <div className='navbar-container-info-navpanel'>
            <div className='navbar-container-info-navpanel-padding'>
               <div className='navbar-container-info-navpanel-links'>
                  {
                     NavBarLinks.map((el)=>{
                        return(
                        <div className='navbar-container-info-navpanel-links-item'>
                           <Link to={el.to}>{el.content}</Link>
                        </div>)
                     })
                  }
               </div>
               <div 
                  className='navbar-container-info-navpanel-links-mobile'>
                  <img
                     className={`navbar-container-info-navpanel-links-mobile-${mobileMenuOpen==true?'active':'inactive'}`}
                     onClick={handleMobileMenuClick} 
                     src={BurgerIcon}/>
               </div>


               <div className='navbar-container-info-navpanel-banner'>
                  <img src='/media/icons/discount.png'/>
                  <span>Reduceri de sezon</span>
               </div>
               <div className='navbar-container-info-navpanel-cart'>
                  <div className='navbar-container-info-navpanel-cart-container'>
                     <img
                        onClick={()=>handleIconRedirect('/checkout')} 
                        src="/media/icons/cart.png"/>
                     <div className='navbar-container-info-navpanel-cart-container-abs'>
                        <spann>{howMany.counter}</spann>
                     </div>
                  </div>
                  <span>{howMany.total} Lei</span>
               </div>

            </div>
               <div className={`navbar-container-info-navpanel-links-mobile-content-${mobileMenuOpen==true?'active':'inactive'}`}>
                  {
                     NavBarLinks.map((el)=>{
                        return(
                              <Link to={el.to}>{el.content}</Link>
                        )
                     })
                  }
               </div>
            
         </div>

      </div>
    )
}

const InfoBarItem = (el)=>{
   return(
      <>
         <div className='navbar-container-info-bar-item'>
            <img src={el.icon}/>
            <span>{el.content}</span>
         </div>
      </>
   )
}


export default NavbarCore;
