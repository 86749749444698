import {
  BrowserRouter as Router,
  Routes ,
  Route,
} from "react-router-dom";

import Home from './pages/Home';
import ProductPage from "./pages/ProductPage";
import './App.css';
import CartPage from "./pages/CartPage";
import SuccessPayment from "./pages/SuccessPayment";
import Despre from "./pages/Despre";
import Contact from "./pages/Contact";
import Termeni from "./pages/Termeni";
import PoliticaCookies from "./pages/PoliticaCookies";
import PoliticaRetur from "./pages/PoliticaRetur";
import PoliticaConfidentialitate from "./pages/PoliticaConfidentialitate";
import Categoryes from "./pages/Categoryes";

const Failure = ()=>{
  return (
    <p> 
      Problema cu efectuarea platii
    </p>
  )
}

const WappWRapper = ({children })=>{
  return(
    <div>
      {children}
      <div
        style={{
          width: 'auto',
          height: '70px',
          position: 'fixed',
          bottom: '0px',
          padding:'10px'
        }}
      >
        <a href="https://api.whatsapp.com/send/?phone=40756233895&text&type=phone_number&app_absent=0">
          <img 
              style={{
                height:'100%',
                objectFit: 'contain'
              }}         
            src="media/icons/whatsapp.png"/>
        </a>
         
      </div>
    </div>
  )
}

function App()
{
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<WappWRapper><Home/></WappWRapper>}/>
          <Route exact path="/categorii" element={<WappWRapper><Categoryes/></WappWRapper>}/>
          <Route exact path="/despre" element={<WappWRapper><Despre/></WappWRapper>}/>
          <Route exact path="/contact" element={<WappWRapper><Contact/></WappWRapper>}/>
          <Route exact path="/product" element={<WappWRapper><ProductPage/></WappWRapper>}/>
          <Route exact path="/checkout" element={<WappWRapper><CartPage/></WappWRapper>}/>
          <Route exact path="/success" element={<SuccessPayment/>}/>
          {/* Legal terms */}
          <Route exact path="/termeni-si-conditii" element={<WappWRapper><Termeni/></WappWRapper>}/>
          <Route exact path="/politica-de-utilizare-cookies" element={<WappWRapper><PoliticaCookies/></WappWRapper>}/>
          <Route exact path="/politica-de-retur" element={<WappWRapper><PoliticaRetur/></WappWRapper>}/>
          <Route exact path="/politica-de-confidentialitate" element={<WappWRapper><PoliticaConfidentialitate/></WappWRapper>}/>
          </Routes>
    </Router>
  );
}

export default App;
